import React from "react";
import styled from "styled-components";
import Social from "../components/Social";
import { Link } from "gatsby";
import background from "../components/bioBg.mp4";
import { CopyToClipboard } from "react-copy-to-clipboard";

const Foot = styled.footer`
  position: relative;
  color: white;
  text-align: center;
  overflow: hidden;
  background: #1c2532;
  .links {
    display: flex;
    align-items: center;
    position: relative;
    max-width: 600px;
    margin: 0 auto;
    a {
      display: inline-block;
      color: white;
      text-transform: uppercase;
      font-family: gotham-bold;
      flex: 0 100%;
      border-radius: 14px;
      padding: 10px;
      transition: all 230ms cubic-bezier(0.2, 0.8, 0.2, 1);
      &:hover {
        background: #131b2799;
      }
    }
  }
  .copyright {
    width: 100%;
    bottom: 0;
    padding: 30px 0;
    opacity: 0.2;
    font-size: 0.9em;
  }
`;

const Contact = styled.div`
  padding: 60px 0 30px;
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    animation: videoFade 2s forwards, scaleUp 20s forwards;
  }
  h1 {
    font-size: 1.9em;
    font-family: merri-reg;
    padding: 10px 0 20px;
    @media (max-width: 500px) {
      font-size: 1.6em;
      padding: 10px 0 20px;
      line-height: 100%;
    }
  }
  h2 {
    font-family: merri-light;
    color: #919cad;
    font-size: 1.3em;
    margin: 0;
    padding: 18px 0;
    max-width: 260px;
    margin: 0 auto;
    b {
      font-family: merri-reg;
      color: white;
    }
  }
  a {
    display: inline-block;
    width: 100%;
    padding: 12px 0 20px;
    color: #ccdaee;
    font-size: 1.4em;
    font-family: merri-bold;
    @media (max-width: 500px) {
      font-size: 1.5em;
      padding: 20px 0 30px;
      line-height: 100%;
    }
  }
  svg {
    display: inline-block;
    width: 50px;
  }
  .wrapper {
    position: relative;
    text-align: center;
    color: white;
    z-index: 1;
  }
  .line {
    display: inline-block;
    height: 3px;
    width: 90%;
    max-width: 295px;
    background: #919cad;
    opacity: 0.1;
    &.faded {
      opacity: 0.1;
    }
  }
  .button {
    display: inline-block;
    background: white;
    border: 0;
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    color: #415d86;
    font-family: gotham-bold;
    text-transform: uppercase;
    font-size: 0.9em;
    padding: 8px 10px;
    animation: clipAnimation 0.5s ease forwards;
    transition: all 130ms cubic-bezier(0.2, 0.8, 0.2, 1);
    &.active {
      background: transparent;
      color: white;
      animation: clipAnimation 0.5s ease forwards;
    }
    &:hover {
      cursor: pointer;
      background: white;
      color: #272e3d;
      transform: scale(1.1);
    }
  }
  .clipboardText {
    font-family: gotham-bold;
    display: inline-block;
    width: 100%;
    left: 0;
    font-size: 1em;
    padding: 16px 0 0;
    opacity: 0;
    transition: all 130ms cubic-bezier(0.2, 0.8, 0.2, 1);
    &.active {
      opacity: 1;
      animation: clipAnimation 130ms ease-in-out forwards;
    }
  }
`;

function copySuccess() {
  var copySuccessBtn = document.getElementById("clipboard");
  copySuccessBtn.classList.toggle("active");
}

function Footer() {
  return (
    <Foot>
      <Contact>
        <video loop muted playsInline autoPlay="autoPlay">
          <source src={background} type="video/mp4" />
        </video>
        <div className="wrapper">
          <h1>Send me a direct message:</h1>
          <a href="mailto:david@davidortiz.me">david@davidortiz.me</a>

          <br />
          <CopyToClipboard onCopy={copySuccess} text="david@davidortiz.me">
            <button className="button">Copy To Clipboard</button>
          </CopyToClipboard>

          <span id="clipboard" className="clipboardText">
            Successfully Copied
          </span>
          <div>
            <span className="line" />
          </div>
        </div>
      </Contact>
      <Social />

      <div className="links">
        <Link to="/">Home</Link>
        <Link to="/projects/">Projects</Link>
        <Link to="/about/">About</Link>
        <Link to="/contact/">Contact</Link>
      </div>

      <div className="copyright">
        <span>© {new Date().getFullYear()} David D. Ortiz</span>
      </div>
    </Foot>
  );
}

export default Footer;
