import React from "react"
import styled from "styled-components"
import { Helmet } from "react-helmet"
import "../components/fonts.css"
import "../components/global.css"
import favicon from "../components/favicon.png"
import Menu from "../components/Menu"
import Projects from "../components/Projects"
import Footer from "../components/Footer"
import background from "../components/bg-typing.mp4"

const Container = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
`
const Header = styled.header`
  position: relative;
  height: 200px;
  color: white;
  text-align: center;
  background: #1c2532;
  height: 65%;
  @media (max-width: 500px) {
    height: 100%;
  }
  video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    animation: videoFade 2s ease-in-out forwards, scaleUp 60s ease-in-out forwards;
  }
  .container {
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
  }
  h1 {
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 20px 0;
    font-size: 3.2em;
    opacity: 0;
    line-height: 100%;
    font-family: merri-bold;
    animation: fade 2s ease-in forwards, slideUp 600ms ease-in-out forwards;
    @media (min-width: 1440px) {
      font-size: 4em;
    }
  }
  h2 {
    position: relative;
    font-size: 1.4em;
    font-family: merri-light;
    opacity: 0;
    animation: fade 1s ease-in-out forwards, slideUp 800ms ease-in-out forwards;
    animation-delay: 250ms;
    @media (max-width: 500px) {
      width: 90%;
      margin: 20px auto;
    }
  }
  h3 {
    position: relative;
    opacity: 0;
    font-size: 1.1em;
    font-family: merri-light;
    animation: fadeSemi 2s ease-in-out forwards, slideUp 1s ease-in-out forwards;
    animation-delay: 350ms;
  }
  p {
    font-size: 1em;
    width: 90%;
    max-width: 600px;
    margin: 0 auto;
  }
  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    text-transform: uppercase;
    font-family: gotham-bold;
    text-align: center;
    background: transparent;
    border: 3px solid rgba(255, 255, 255, 0.2);
    width: 90%;
    max-width: 190px;
    margin: 20px auto 0;
    padding: 12px;
    border-radius: 4px;
    transition: all 130ms cubic-bezier(0.2, 0.8, 0.2, 1);
    font-size: 0.9em;
    position: relative;
    opacity: 0;
    animation: fade 1s ease-in-out forwards, slideUp 1s ease-in-out forwards;
    animation-delay: 350ms;
    &:hover {
      background: white;
      color: black;
      transform: scale(1.1);
      svg path {
        fill: #1c2532;
      }
    }
    span {
      display: block;
      white-space: nowrap;
      padding: 0 10px;
      margin: 0 auto;
      svg {
        position: relative;
        top: 3px;
        width: 32px;
        path {
          transition: all 130ms cubic-bezier(0.2, 0.8, 0.2, 1);
        }
      }
    }
  }
`

export default () => (
  <Container>
    <Helmet>
      <meta charSet='utf-8' />
      <title>David D. Ortiz | Product Designer</title>
      <link rel='icon' type='image/png' href={favicon} sizes='16x16' />
      <link rel='alternate' href='https://www.davidortiz.me/projects/' hreflang='en-us' />
      <link rel='canonical' href='https://www.davidortiz.me/projects/' />
      <meta http-equiv='Content-Type' content='text/html; charset=utf-8' />
      <meta name='description' content='Product Designer | UX, Front End & Deployment for Remote Startups.' />
      <meta name='viewport' content='width=device-width, initial-scale=1' />

      <meta property='og:title' content='David D. Ortiz | Product Designer' />
      <meta property='og:site_name' content='Personal Portfolio 2019' />
      <meta property='og:description' content='Product Designer | UX, Front End & Deployment for Remote Startups.' />
    </Helmet>
    <Menu />
    <Header>
      <video loop muted playsInline autoPlay='autoPlay'>
        <source src={background} type='video/mp4' />
      </video>
      <div className='container'>
        <h1>Trusted With Design+Code</h1>
        <h2>Live projects pushed to production in the past 6 months</h2>
        <h3>Updated April 2023</h3>
        <a href='https://www.behance.net/daviddortiz' target='_blank' rel='noopener noreferrer'>
          <span>
            <svg viewBox='0 0 365 229' fill='none' xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M106 0C116.5 0 126.2 0.8 135.1 3.2C144 4.8 151.3 8.1 157.8 12.1C164.3 16.1 169.1 21.8 172.4 29.1C175.6 36.4 177.3 45.3 177.3 55C177.3 66.3 174.9 76 169.2 83.3C164.3 90.6 156.3 97.1 146.5 101.9C160.3 105.9 170.8 113.2 177.2 122.9C183.6 132.6 187.7 144.7 187.7 158.5C187.7 169.8 185.3 179.5 181.2 187.6C177.2 195.7 170.7 203 163.4 207.8C156.1 212.7 147.2 216.7 137.5 219.1C127.8 221.5 118.1 223.1 108.4 223.1L0 223.3V0H106ZM99.5 90.6C108.4 90.6 115.7 88.2 121.3 84.1C126.9 80 129.4 72.8 129.4 63.9C129.4 59 128.6 54.2 127 51C125.4 47.8 123 45.3 119.7 42.9C116.5 41.3 113.2 39.7 109.2 38.9C105.2 38.1 101.1 38.1 96.3 38.1H49.4V90.7C49.4 90.6 99.5 90.6 99.5 90.6ZM101.9 186.1C106.8 186.1 111.6 185.3 115.7 184.5C119.7 183.7 123.8 182.1 127 179.6C130.2 177.1 132.7 174.7 135.1 170.7C136.7 166.7 138.3 161.8 138.3 156.1C138.3 144.8 135.1 136.7 128.6 131C122.1 126.1 113.2 123.7 102.7 123.7H49.4V186H101.9V186.1Z'
                fill='white'
              />
              <path
                d='M258.1 185.3C264.6 191.8 274.3 195 287.2 195C296.1 195 304.2 192.6 310.7 188.5C317.2 183.6 321.2 178.8 322.8 173.9H362.4C355.9 193.3 346.2 207.1 333.3 216C320.4 224.1 305 228.9 286.4 228.9C273.5 228.9 262.1 226.5 251.6 222.4C241.1 218.4 233 212.7 225.7 204.6C218.4 197.3 212.8 188.4 209.5 177.9C205.5 167.4 203.8 156.1 203.8 143.1C203.8 131 205.4 119.6 209.5 109.1C213.6 98.5999 219.2 89.6999 226.5 81.5999C233.8 74.2999 242.7 67.7999 252.4 63.7999C262.9 59.7999 273.4 57.2999 286.4 57.2999C300.2 57.2999 312.3 59.6999 322.8 65.3999C333.3 71.0999 341.4 77.4999 347.9 87.1999C354.4 96.0999 359.2 106.6 362.5 117.9C364.1 129.2 364.9 140.6 364.1 153.5H246.8C246.8 166.7 251.6 178.8 258.1 185.3ZM309.1 100.3C303.4 94.5999 294.5 91.3999 284 91.3999C276.7 91.3999 271.1 92.9999 266.2 95.3999C261.3 97.7999 258.1 101.1 254.9 104.3C251.7 107.5 250 111.6 249.2 115.6C248.4 119.6 247.6 122.9 247.6 126.1H320.4C318.8 114.1 314.7 106 309.1 100.3ZM237.9 15.3999H328.5V37.1999H237.9V15.3999Z'
                fill='white'
              />
            </svg>
          </span>
          <span>View Archive</span>
        </a>
      </div>
    </Header>
    <Projects />
    <Footer />
  </Container>
)
